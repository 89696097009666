import Joi from 'joi'

export const string = label => {
  const baseSchema = !label ? Joi.string() : Joi.string().label(label)
  return {
    optional: baseSchema.allow('').optional(),
    required: baseSchema.required(),
  }
}

export const email = schema =>
  schema.email({ tlds: { allow: false } }).example('e.g. you@domain.tld')
export const phone = schema =>
  schema
    .length(10)
    .pattern(/^[0-9]+$/)
    .example('e.g. (206) 555-0100')
