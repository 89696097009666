import React from 'react'
import InputHidden from '@/components/SoleraNetwork/FormControls/InputHidden'
import InputText from '@/components/SoleraNetwork/FormControls/InputText'
import FileUpload from '@/components/SoleraNetwork/FormControls/FileUpload'
import TextArea from '@/components/SoleraNetwork/FormControls/TextArea'
import SelectOptions from '@/components/SoleraNetwork/FormControls/SelectOptions'
import SelectRadios from '@/components/SoleraNetwork/FormControls/SelectRadios'
import SelectCheckboxes from '@/components/SoleraNetwork/FormControls/SelectCheckboxes'

const FormField = ({ type, ...props }) => {
  const FIELD_TYPES = {
    input_hidden: <InputHidden {...props} />,
    input_text: <InputText {...props} />,
    input_file: <FileUpload {...props} />,
    textarea: <TextArea {...props} />,
    select_options: <SelectOptions {...props} />,
    multi_value_single_select: <SelectRadios {...props} />,
    multi_value_multi_select: <SelectCheckboxes inlineLabel {...props} />,
  }

  if (type in FIELD_TYPES) {
    return FIELD_TYPES[type]
  }

  return null
}

export default FormField
