export const CU_LIST_ID = process.env.GATSBY_ITERABLE_CU_LIST_ID

export const CU_FULL_NAME = 'name'
export const CU_EMAIL = 'email'
export const CU_COMPANY = 'company'
export const CU_PHONE = 'phone'
export const CU_COMPANY_TYPE = 'companyType'
export const CU_MESSAGE = 'message'

export const CU_TITLE = 'title'
export const CU_WEBSITE = 'website'
export const CU_REASON = 'reason'
export const CU_REFERRAL = 'referral'
export const CU_PROGRAM_DELIVERY = 'programDelivery'
export const CU_DIFFERENTIATOR = 'differentiator'
export const CU_TARGET_AUDIENCE = 'targetAudience'
export const CU_PROGRAM_AVAILABILITY_REGION = 'programAvailabilityRegion'
export const CU_PROGRAM_DETAILS = 'programDetails'
export const CU_PROGRAM_AVAILABILITY_LANGUAGES = 'programAvailabilityLanguages'

export const companyTypes = [
  { label: 'Health Plan', value: 'healthPlan' },
  { label: 'Service Provider', value: 'provider' },
  { label: 'Employer', value: 'employer' },
  { label: 'Other', value: 'other' },
]

export const programDeliveryChannels = [
  { label: 'Digital (app)', value: 'digitalApp' },
  { label: 'Digital (online only)', value: 'digitalOnline' },
  { label: 'Community based', value: 'communityBased' },
  { label: 'Other', value: 'other' },
]

export const targetAudiences = [
  { label: 'D2c', value: 'd2c' },
  { label: 'Health Plans', value: 'healthPlans' },
  { label: 'Employer Groups', value: 'employerGroups' },
  { label: 'Other', value: 'other' },
]

export const programAvailabilityRegions = [
  { label: 'Local', value: 'local' },
  { label: 'Regional', value: 'regional' },
  { label: 'National (U.S.)', value: 'nationalUS' },
  { label: 'International/global', value: 'international' },
]

export const fields = [
  {
    name: CU_FULL_NAME,
    label: 'Your Name',
    labelRaw: 'Full Name',
    type: 'input_text',
  },
  { name: CU_TITLE, label: 'Title', type: 'input_text', advanced: true },
  {
    name: CU_EMAIL,
    label: 'Your Email Address',
    labelRaw: 'Email',
    type: 'input_text',
  },
  { name: CU_COMPANY, label: 'Company', type: 'input_text', grid: { xs: 6 } },
  { name: CU_PHONE, label: 'Phone', type: 'input_text', grid: { xs: 6 } },
  { name: CU_WEBSITE, label: 'Website', type: 'input_text', advanced: true },
  {
    name: CU_REASON,
    label: 'I am emailing because...',
    labelRaw: 'Reason for message',
    type: 'input_text',
    advanced: true,
  },
  {
    name: CU_REFERRAL,
    label: 'How did you hear about us?',
    labelRaw: 'Referred by',
    type: 'input_text',
    advanced: true,
  },
  {
    name: CU_COMPANY_TYPE,
    labelRaw: 'Company Type',
    type: 'multi_value_single_select',
    values: companyTypes,
  },
  {
    name: CU_MESSAGE,
    label: 'Any specific concerns we can address?',
    labelRaw: 'Concerns',
    type: 'textarea',
    rows: 4,
  },
  {
    name: CU_PROGRAM_DELIVERY,
    label: 'How do you deliver your program today? Check all that apply',
    type: 'multi_value_multi_select',
    values: programDeliveryChannels,
    advanced: true,
  },
  {
    name: CU_DIFFERENTIATOR,
    label: 'Tell us how you differentiate yourself in the marketplace?',
    labelRaw: 'Market Differentiators',
    type: 'textarea',
    rows: 3,
    advanced: true,
  },
  {
    name: CU_TARGET_AUDIENCE,
    label: 'Who are your typical customers? Check all that apply',
    type: 'multi_value_multi_select',
    values: targetAudiences,
    advanced: true,
  },
  {
    name: CU_PROGRAM_AVAILABILITY_REGION,
    label:
      'What states/regions of the United States (or international) is your program available? Select all that apply',
    type: 'multi_value_multi_select',
    values: programAvailabilityRegions,
    advanced: true,
  },
  {
    name: CU_PROGRAM_DETAILS,
    label: 'Details',
    type: 'textarea',
    rows: 3,
    advanced: true,
  },
  {
    name: CU_PROGRAM_AVAILABILITY_LANGUAGES,
    label: 'Is your program offered in any other languages?',
    labelShort: 'Program Languages',
    type: 'textarea',
    rows: 3,
    advanced: true,
  },
]

export const getFieldLabel = ({ label, labelShort }) => labelShort || label
export const getField = fieldName => fields.find(f => f.name === fieldName)
