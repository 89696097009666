import * as C from './ContactUsForm.c'
import * as V from '@/libs/formValues'

const advancedInitialValues = {
  [C.CU_TITLE]: V.string,
  [C.CU_WEBSITE]: V.string,
  [C.CU_REASON]: V.string,
  [C.CU_REFERRAL]: V.string,
  [C.CU_PROGRAM_DELIVERY]: V.string,
  [C.CU_DIFFERENTIATOR]: V.string,
  [C.CU_TARGET_AUDIENCE]: V.string,
  [C.CU_PROGRAM_AVAILABILITY_REGION]: V.string,
  [C.CU_PROGRAM_DETAILS]: V.string,
  [C.CU_PROGRAM_AVAILABILITY_LANGUAGES]: V.string,
}

const initialValues = advanced => {
  const advancedFields = !advanced ? {} : advancedInitialValues
  return {
    [C.CU_FULL_NAME]: V.string,
    [C.CU_EMAIL]: V.string,
    [C.CU_COMPANY]: V.string,
    [C.CU_PHONE]: V.string,
    [C.CU_COMPANY_TYPE]: V.string,
    [C.CU_MESSAGE]: V.string,
    ...advancedFields,
  }
}

export default initialValues
