import React from 'react'
import { Row, Col } from 'react-bootstrap'
import IterableForm from '@/components/IterableForm'
import FormField from '@/components/SoleraNetwork/FormControls/FormField'
import { css } from '@emotion/react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import * as C from './ContactUsForm.c'
import validationSchema from './ContactUsForm.v'
import initialValues from './ContactUsForm.i'

const heroStyle = css`
  background-color: #f1f2f2;
  padding: 5em 0;
  margin: 0 !important;
  text-align: center;

  h2 {
    color: #2067b7;
  }

  form {
    text-align: left;
  }
`

const ContactUsForm = ({
  moduleWidth,
  moduleAlignment,
  heading,
  hero,
  richtext,
}) => {
  const description =
    richtext &&
    richtext.raw &&
    documentToReactComponents(JSON.parse(richtext.raw))
  const centered = moduleAlignment === 'center'
  const handleSubmit = data => {
    fetch('/api/emailMessage', {
      method: 'POST',
      body: JSON.stringify({ data }, null, 4),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return {
      listId: Number(C.CU_LIST_ID),
      subscribers: [
        {
          email: data[C.CU_EMAIL],
          dataFields: data,
          preferUserId: false,
          mergeNestedObjects: false,
        },
      ],
    }
  }

  return (
    <Row
      className={`${centered ? 'justify-content-md-center' : ''} ${
        !hero ? 'mb-3' : ''
      } w-100`}
      css={hero ? heroStyle : ''}
    >
      <Col sm={12} md={moduleWidth}>
        {heading && <h2 className="mb-3">{heading}</h2>}
        {richtext && <p>{description}</p>}
        <IterableForm
          input="/api/subscribe"
          defaultValues={initialValues(!hero)}
          schema={validationSchema(!hero)}
          onSubmit={handleSubmit}
        >
          <Row>
            {C.fields
              .filter(f => (!hero ? true : !f.advanced))
              .map(({ label: placeholder, grid, ...f }) => (
                <Col key={f.name} xs={grid?.xs || 12}>
                  <FormField placeholder={placeholder} {...f} />
                </Col>
              ))}
          </Row>
        </IterableForm>
      </Col>
    </Row>
  )
}

export const mapContactUsFormProps = () => ({})

export default React.memo(ContactUsForm)
