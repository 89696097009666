import Joi from 'joi'
import * as C from './ContactUsForm.c'
import * as S from '@/libs/formSchema'

const strField = fieldName => S.string(C.getFieldLabel(C.getField(fieldName)))

const advancedSchema = {
  [C.CU_TITLE]: strField(C.CU_TITLE).required,
  [C.CU_WEBSITE]: strField(C.CU_WEBSITE).required,
  [C.CU_REASON]: strField(C.CU_REASON).required,
  [C.CU_REFERRAL]: strField(C.CU_REFERRAL).optional,
  [C.CU_PROGRAM_DELIVERY]: Joi.array()
    .items(Joi.string().valid(...C.programDeliveryChannels.map(d => d.value)))
    .optional(),
  [C.CU_DIFFERENTIATOR]: strField(C.CU_DIFFERENTIATOR).optional,
  [C.CU_TARGET_AUDIENCE]: Joi.array()
    .items(Joi.string().valid(...C.targetAudiences.map(a => a.value)))
    .optional(),
  [C.CU_PROGRAM_AVAILABILITY_REGION]: Joi.array()
    .items(
      Joi.string().valid(...C.programAvailabilityRegions.map(r => r.value))
    )
    .required(),
  [C.CU_PROGRAM_DETAILS]: strField(C.CU_PROGRAM_DETAILS).optional,
  [C.CU_PROGRAM_AVAILABILITY_LANGUAGES]: strField(
    C.CU_PROGRAM_AVAILABILITY_LANGUAGES
  ).optional,
  [C.CU_COMPANY]: S.string('Company').required,
}

const validationSchema = advanced => {
  const advancedFields = !advanced ? {} : advancedSchema
  return Joi.object({
    [C.CU_FULL_NAME]: S.string('Name').required,
    [C.CU_EMAIL]: Joi.string()
      .label('Email')
      .email({ tlds: { allow: false } })
      .required(),
    [C.CU_COMPANY]: S.string('Company').optional,
    [C.CU_PHONE]: S.phone(S.string('Phone').required),
    [C.CU_COMPANY_TYPE]: S.string('Company Type').optional,
    [C.CU_MESSAGE]: S.string('Message').optional,
    ...advancedFields,
  })
}

export default validationSchema
