import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { toast } from 'react-toastify'
import styled from '@emotion/styled'

const ActionWrapper = styled.div`
  text-align: center;
`
const IterableForm = ({
  input,
  onSubmit,
  schema,
  defaultValues,
  children,
  ...props
}) => {
  const methods = useForm({
    resolver: schema && joiResolver(schema),
    defaultValues,
  })
  const { handleSubmit, reset } = methods

  const onToastClose = () => reset(defaultValues)

  const onFormSubmit = data => {
    const body = onSubmit(data)
    fetch(input, {
      method: 'POST',
      body: JSON.stringify(body, null, 4),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        if (data.invalidEmails.length > 0 || data.invalidUserIds > 0) {
          console.error('Something went wrong')
        }
        if (data.successCount > 0) {
          toast('Your submission has been received.', {
            position: 'bottom-right',
            type: 'success',
            onClose: onToastClose,
          })
        }
      })
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form
          name="iterable_optin"
          onSubmit={handleSubmit(onFormSubmit)}
          {...props}
        >
          {children}
          <ActionWrapper>
            <Button type="submit" variant="success" size="lg">
              Submit
            </Button>
          </ActionWrapper>
        </Form>
      </FormProvider>
    </>
  )
}

export default IterableForm
