import React from 'react'
import { Form } from 'react-bootstrap'
import { useFormContext, useFormState } from 'react-hook-form'

const SelectRadios = ({ name, values, required, ...props }) => {
  const { register, control } = useFormContext()
  const { errors } = useFormState({ control })
  const error = errors[name]
  return (
    <>
      {values.map((value, index) => (
        <Form.Check
          key={`fieldSelectRadio-${index.toString()}`}
          inline
          label={value.label}
          type="radio"
          id={name}
          name={name}
          value={value.value}
          required={required}
          inValid={!!error}
          {...register(name)}
          {...props}
        />
      ))}
      {!!error && (
        <Form.Control.Feedback type="invalid" className="mb-3">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  )
}

export default SelectRadios
